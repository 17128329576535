import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PageBody, PageGrid } from "../../theme";
import { GET_CONTACT_BY_ID, UPDATE_CONTACT } from "../../api";
import { DataContext } from "../../context/DataContext";
import { ContactReducer } from "../../utilities";
import {
  AddContact,
  ActivePRS,
  Editor,
  InfoCard,
  Notes,
  ComingSoon,
  Address,
  Tags,
  Interactions,
} from "../../components";

const Contact = (props: any) => {
  const { id } = useParams();
  const { projects, services } = useContext(DataContext);
  const [contact, setContact] = useState(null);
  const [modified, setModified] = useState("");
  const [editor, setEditor] = useState(false);
  const [managerStatus, setManagerStatus] = useState(false);
  const [updateStatus] = useMutation(UPDATE_CONTACT);
  const { data: contactData, error: contactDataError } = useQuery(
    GET_CONTACT_BY_ID,
    {
      variables: { id },
    }
  );

  useEffect(() => {
    if (contactData && !contactDataError) {
      setContact(contactData?.getContactById);
      setModified(contact?.updatedAt.substr(0, 10));
    } else if (contactDataError) {
      console.error({
        message: "Error fetching contact data",
        error: contactDataError,
      });
    }
  }, [contactData, contact?.createdAt, contact?.updatedAt, contactDataError]);

  useEffect(() => {
    if (props?.user.attributes["custom:userGroup"] !== "Team Member") {
      setManagerStatus(true);
    }
  }, [props?.user]);

  const handleEdit = (e) => {
    e.preventDefault();
    setEditor(!editor);
  };

  const handleStatusUpdate = (status) => {
    updateStatus({
      variables: {
        id,
        input: {
          status: status,
        },
      },
      refetchQueries: [{ query: GET_CONTACT_BY_ID }],
    });
  };

  return (
    <>
      {editor && (
        <Editor
          type="contact"
          component={
            <AddContact {...props} isEdit={true} id={id} data={contact} />
          }
        />
      )}
      <PageBody>
        <div className="last-modified">
          <p>
            Last Modified:
            <span>{modified}</span>
          </p>
        </div>
        <PageGrid>
          <div className="item-left-column">
            <InfoCard
              title="Contact Information"
              height="650px"
              width="100%"
              headerOption="edit"
              onBtnClick={handleEdit}
              fields={[
                <Address
                  type="contact"
                  data={contact}
                  canEdit={managerStatus}
                  isManager={managerStatus}
                  handleStatusUpdate={handleStatusUpdate}
                />,
              ]}
            />
            <div
              className="grid-block"
              style={{ height: "650px", margin: "25px" }}
            />
          </div>
          <div className="item-middle-column">
            <InfoCard
              title="active services"
              height="300px"
              width="100%"
              fields={[
                <ActivePRS
                  data={services}
                  type="services"
                  id={id}
                  pairType="contacts"
                />,
              ]}
            />
            <InfoCard
              title="active projects"
              height="300px"
              width="100%"
              fields={[
                <ActivePRS
                  data={projects}
                  type="projects"
                  id={id}
                  pairType="contacts"
                />,
              ]}
            />
            <InfoCard
              title="Interactions"
              height="300px"
              width="100%"
              fields={[
                <Interactions
                  id={id}
                  type="contact"
                  user={props.user}
                  data={contact?.interactions}
                  reducer={ContactReducer}
                  refetchQuery={GET_CONTACT_BY_ID}
                />,
              ]}
            />
            <InfoCard
              title="marketing"
              height="300px"
              width="100%"
              fields={[<ComingSoon fieldName="Marketing" release="4" />]}
            />
          </div>
          <div className="item-right-column">
            <InfoCard
              title="tags"
              height="650px"
              width="100%"
              fields={[<Tags type="contact" data={contact} />]}
            />
            <InfoCard
              title="Notes"
              height="650px"
              width="100%"
              fields={[
                <Notes
                  id={id}
                  data={contact?.notes}
                  reducer={ContactReducer}
                  refetchQuery={GET_CONTACT_BY_ID}
                />,
              ]}
            />
          </div>
        </PageGrid>
      </PageBody>
    </>
  );
};

export default Contact;
