import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PageBody, PageGrid } from "../../theme";
import { CompanyReducer } from "../../utilities";
import { DataContext } from "../../context/DataContext";
import { GET_COMPANY_BY_ID, UPDATE_COMPANY } from "../../api";
import {
  AddCompany,
  ActivePRS,
  Editor,
  InfoCard,
  Notes,
  Address,
  ContactToCompany,
  Tags,
  InteractionToSingleItem,
} from "../../components";

const Company = (props: any) => {
  const { id } = useParams();
  const { services, projects } = useContext(DataContext);
  const [company, setCompany] = useState(null);
  const [modified, setModified] = useState("");
  const [editor, setEditor] = useState(false);
  const [managerStatus, setManagerStatus] = useState(false);
  const [updateStatus] = useMutation(UPDATE_COMPANY);
  const { data: companyData, error: companyDataError } = useQuery(
    GET_COMPANY_BY_ID,
    {
      variables: { id },
    }
  );

  useEffect(() => {
    if (companyData && !companyDataError) {
      setCompany(companyData?.getCompanyById);
      setModified(company?.updatedAt.substr(0, 10));
    } else if (companyDataError) {
      console.error({
        message: "Error fetching company data",
        error: companyDataError,
      });
    }
  }, [companyData, company?.createdAt, company?.updatedAt, companyDataError]);

  useEffect(() => {
    if (props?.user.attributes["custom:userGroup"] !== "Team Member") {
      setManagerStatus(true);
    }
  }, [props?.user]);

  const handleEdit = (e) => {
    e.preventDefault();
    setEditor(!editor);
  };

  const handleStatusUpdate = (status) => {
    updateStatus({
      variables: {
        id,
        input: {
          status: status,
        },
      },
      refetchQueries: [{ query: GET_COMPANY_BY_ID }],
    });
  };

  return (
    <>
      {editor && (
        <Editor
          type="company"
          component={
            <AddCompany {...props} isEdit={true} id={id} data={company} />
          }
        />
      )}
      <PageBody>
        <div className="last-modified">
          <p>
            Last Modified:
            <span>{modified}</span>
          </p>
        </div>
        <PageGrid>
          <div className="item-left-column">
            <InfoCard
              title={
                company?.status === "Inactive"
                  ? company?.name + " (Inactive)"
                  : company?.name
              }
              height="650px"
              width="100%"
              headerOption="edit"
              onBtnClick={handleEdit}
              fields={[
                <Address
                  type="company"
                  data={company}
                  canEdit={managerStatus}
                  isManager={managerStatus}
                  handleStatusUpdate={handleStatusUpdate}
                />,
              ]}
            />
            <div
              className="grid-block"
              style={{ height: "650px", margin: "25px" }}
            />
          </div>
          <div className="item-middle-column">
            <InfoCard
              title="Company Contacts"
              height="300px"
              width="100%"
              fields={[<ContactToCompany data={id} />]}
            />
            <InfoCard
              title="services"
              height="300px"
              width="100%"
              fields={[
                <ActivePRS
                  data={services}
                  type="services"
                  id={id}
                  pairType="company"
                />,
              ]}
            />
            <InfoCard
              title="projects"
              height="300px"
              width="100%"
              fields={[
                <ActivePRS
                  data={projects}
                  type="projects"
                  id={id}
                  pairType="funder"
                />,
              ]}
            />
            <InfoCard
              title="Interactions"
              height="300px"
              width="100%"
              fields={[<InteractionToSingleItem id={id} type="company" />]}
            />
          </div>
          <div className="item-right-column">
            <InfoCard
              title="tags"
              height="650px"
              width="100%"
              onBtnClick={handleEdit}
              fields={[<Tags type="company" data={company} />]}
            />
            <InfoCard
              title="notes"
              height="650px"
              width="100%"
              fields={[
                <Notes
                  id={id}
                  data={company?.notes}
                  reducer={CompanyReducer}
                  refetchQuery={GET_COMPANY_BY_ID}
                />,
              ]}
            />
          </div>
        </PageGrid>
      </PageBody>
    </>
  );
};

export default Company;
