import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
  query getAllContacts($nextToken: String) {
    getAllContacts(nextToken: $nextToken) {
      contacts {
        id
        title
        name
        phone
        mobile
        ext
        work
        fax
        primaryEmail
        secondaryEmail
        companyName
        jobTitle
        division
        isPrimary
        industries
        programs
        contactTypes
        specialist
        categories
        country
        province
        county
        city
        street
        street2
        postal
        status
        createdBy
      }
      nextToken
    }
  }
`;

export const GET_CONTACT_BY_ID = gql`
  query getContactById($id: ID!) {
    getContactById(id: $id) {
      id
      title
      name
      phone
      mobile
      ext
      work
      fax
      primaryEmail
      secondaryEmail
      companyName
      jobTitle
      division
      isPrimary
      industries
      programs
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      createdBy
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        programOrProject
        createdAt
      }
      createdAt
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($newContact: ContactInput!) {
    createContact(newContact: $newContact) {
      id
      title
      name
      phone
      mobile
      ext
      work
      fax
      primaryEmail
      secondaryEmail
      companyName
      jobTitle
      division
      isPrimary
      industries
      programs
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      createdBy
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact

        createdAt
      }
      createdAt
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($id: ID!, $input: ContactInput!) {
    updateContact(id: $id, input: $input) {
      id
      title
      name
      phone
      mobile
      ext
      work
      fax
      primaryEmail
      secondaryEmail
      companyName
      jobTitle
      division
      isPrimary
      industries
      programs
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      createdBy
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact

        createdAt
      }
      createdAt
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
      title
      name
      phone
      mobile
      ext
      work
      fax
      primaryEmail
      secondaryEmail
      companyName
      jobTitle
      division
      isPrimary
      industries
      programs
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      createdBy
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact

        createdAt
      }
      createdAt
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;
