/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Table, AddItem, AddCompany } from "../../components";
import { CompanySchema, MultipleIdToNameFormatter } from "../../utilities";
import { PageBody } from "../../theme";
import { DataContext } from "../../context/DataContext";

const Companies = (props: any) => {
  const [addItem, setAddItem] = useState(false);
  const {
    companies,
    setCompanies,
    certifications,
    industries,
    programs,
    contactTypes,
    users,
    categories,
  } = useContext(DataContext);

  useEffect(() => {
    if (companies) {
      setCompanies(
        companies
          .map((item: any) => {
            const formattedCertifications = item.certifications
              ? MultipleIdToNameFormatter({
                  data: certifications,
                  fieldToMatch: item.certifications,
                })
              : [];
            const formattedIndustries = item.industries
              ? MultipleIdToNameFormatter({
                  data: industries,
                  fieldToMatch: item.industries,
                })
              : [];
            const formattedPrograms = item.programs
              ? MultipleIdToNameFormatter({
                  data: programs,
                  fieldToMatch: item.programs,
                })
              : [];
            const formattedContactTypes = item.contactTypes
              ? MultipleIdToNameFormatter({
                  data: contactTypes,
                  fieldToMatch: item.contactTypes,
                })
              : [];
            const formattedSpecialist = item.specialist
              ? MultipleIdToNameFormatter({
                  data: users,
                  fieldToMatch: item.specialist,
                })
              : [];
            const formattedCategories = item.categories
              ? MultipleIdToNameFormatter({
                  data: categories,
                  fieldToMatch: item.categories,
                })
              : [];

            return {
              ...item,
              formattedCertifications,
              formattedIndustries,
              formattedPrograms,
              formattedContactTypes,
              formattedSpecialist,
              formattedCategories,
            };
          })
          .filter(
            (item: any) => item.id !== "759911a6-c3ab-47a6-97c0-ce4bcb6b5d78"
          )
          .sort((a: any, b: any) => {
            if (a.status === "Pending") {
              return -1;
            } else if (b.status === "Pending") {
              return 1;
            } else if (a.status === "Active") {
              return -1;
            } else if (b.status === "Active") {
              return 1;
            } else {
              return 0;
            }
          })
      );
    }
  }, []);

  const handleAdd = (e) => {
    e.preventDefault();
    setAddItem(!addItem);
  };

  return (
    <>
      {addItem && (
        <AddItem type="company" component={<AddCompany {...props} />} />
      )}
      <PageBody>
        <Table
          showAdd
          handleAdd={handleAdd}
          title="Companies"
          count={companies?.length}
          rowDataProp={companies}
          colDefProp={CompanySchema}
        />
      </PageBody>
    </>
  );
};

export default Companies;
