export const INITIAL_COMPANY_STATE = () => {
  return {
    name: "",
    phone: "",
    ext: "",
    fax: "",
    email: "",
    website: "",
    isFunder: false,
    certifications: [],
    industries: [],
    programs: [],
    contactTypes: [],
    specialist: [],
    categories: [],
    country: null,
    province: "",
    county: "",
    city: "",
    street: "",
    street2: "",
    postal: "",
    notes: [],
    interactions: [],
    status: "",
    updates: [],
  };
};

export const CompanyReducer = (state, action) => {
  switch (action.field) {
    case "name":
    case "phone":
    case "ext":
    case "fax":
    case "email":
    case "website":
    case "industries":
    case "programs":
    case "contactTypes":
    case "certifications":
    case "specialist":
    case "categories":
    case "country":
    case "province":
    case "county":
    case "city":
    case "street":
    case "street2":
    case "postal":
    case "notes":
    case "interactions":
    case "status":
    case "updates":
      return {
        ...state,
        [action.field]: action.payload,
      };
    case "isFunder":
      return {
        ...state,
        isFunder: !state.isFunder,
      };
    default:
      return;
  }
};
